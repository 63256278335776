import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import BookShelf from "./BookShelf";
const ListBooks = ({ books, changebooks }) => {
  return (
    <div className="list-books">
      <div className="list-books-title">
        <h1>MyReads</h1>
      </div>
      <div className="list-books-content">
        <div>
          <BookShelf
            shelfname="currentlyReading"
            books={books}
            changebooks={changebooks}
          />
          <BookShelf
            shelfname="wantToRead"
            books={books}
            changebooks={changebooks}
          />
          <BookShelf shelfname="read" books={books} changebooks={changebooks} />
        </div>
      </div>
      <div className="open-search">
        <Link to="/search">Add a book</Link>
      </div>
    </div>
  );
};

export default ListBooks;
ListBooks.prototype = {
  books: PropTypes.array.isRequired,
  changebooks: PropTypes.func.isRequired,
};
