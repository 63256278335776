import React from "react";
import { useState } from "react";
import * as bookapi from "./BooksAPI";
import { Link } from "react-router-dom";

const SearchBoook = ({ books, changebooks }) => {
  const [query, setquery] = useState("");
  const [sbooks, setsbooks] = useState([]);
  const handleCommonBook = (newshelf, reqbook) => {
    const newbooks = books.map((book) => {
      if (book.id === reqbook.id) {
        bookapi.update(book, newshelf);
        book.shelf = newshelf;
      }
      return book;
    });
    changebooks(newbooks);
  };
  const handlenewbook = (newshelf, reqbook) => {
    if (newshelf !== "none") {
      reqbook.shelf = newshelf;
      bookapi.update(reqbook, newshelf);
      changebooks([...books, reqbook]);
    }
  };
  let filtertimeout;
  const handleQueryChange = async (event) => {
    setquery(event.target.value);
    clearTimeout(filtertimeout);
    setTimeout(async () => {
      if (event.target.value) {
        const newbooks = await bookapi.search(event.target.value, 20);
        console.log(newbooks);
        if (newbooks.length > 0) {
          const allbooks = newbooks.map((nbook) => {
            books.map((book) => {
              if (nbook.id === book.id) {
                nbook.shelf = book.shelf;
              }
              return book;
            });
            return nbook;
          });
          setsbooks(allbooks);
        } else {
          setsbooks([]);
        }
      } else {
        setsbooks([]);
      }
    }, 250);
  };
  return (
    <div>
      <div className="search-books">
        <div className="search-books-bar">
          <Link to="/" className="close-search">
            Close
          </Link>
          <div className="search-books-input-wrapper">
            <input
              type="text"
              placeholder="Search by title, author, or ISBN"
              value={query}
              onChange={(event) => {
                handleQueryChange(event);
              }}
            />
          </div>
        </div>
        <div className="search-books-results">
          <ol className="books-grid">
            {/*for new book*/}
            {sbooks.length > 0 ? (
              sbooks.map((book) => (
                <li key={book.id}>
                  <div className="book">
                    <div className="book-top">
                      {book.imageLinks ? (
                        <div
                          className="book-cover"
                          style={{
                            width: 128,
                            height: 193,
                            backgroundImage: `url(${book.imageLinks.smallThumbnail})`,
                          }}
                        ></div>
                      ) : (
                        ""
                      )}

                      <div className="book-shelf-changer">
                        {book.shelf ? (
                          <select
                            defaultValue={book.shelf}
                            onClick={(event) =>
                              handleCommonBook(event.target.value, book)
                            }
                          >
                            <option value="none" disabled>
                              Move to...
                            </option>
                            <option value="currentlyReading">
                              Currently Reading
                            </option>
                            <option value="wantToRead">Want to Read</option>
                            <option value="read">Read</option>
                            <option value="none">None</option>
                          </select>
                        ) : (
                          <select
                            defaultValue="none"
                            onClick={(event) =>
                              handlenewbook(event.target.value, book)
                            }
                          >
                            <option disabled>Move to...</option>
                            <option value="currentlyReading">
                              Currently Reading
                            </option>
                            <option value="wantToRead">Want to Read</option>
                            <option value="read">Read</option>
                            <option value="none">None</option>
                          </select>
                        )}
                      </div>
                    </div>
                    <div className="book-title">
                      {book.title ? book.title : ""}
                    </div>
                    <div className="book-authors">
                      {book.authors ? book.authors.map((author) => author) : ""}
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <div>Search for a Book</div>
            )}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default SearchBoook;
