import React from "react";
import * as bookapi from "./BooksAPI";
import PropTypes from "prop-types";

const BookShelf = ({ shelfname, books, changebooks }) => {
  const handlechange = (newshelf, reqbook) => {
    const newbooks = books.map((book) => {
      if (book.id === reqbook.id) {
        bookapi.update(book, newshelf);
        book.shelf = newshelf;
      }
      return book;
    });
    changebooks(newbooks);
  };
  return (
    <div className="bookshelf">
      <h2 className="bookshelf-title">{shelfname}</h2>
      <div className="bookshelf-books">
        <ol className="books-grid">
          {books
            .filter((book) => {
              return book.shelf === `${shelfname}`;
            })
            .map((book) => (
              <li key={book.id}>
                <div className="book">
                  <div className="book-top">
                    <div
                      className="book-cover"
                      style={{
                        width: 128,
                        height: 193,
                        backgroundImage: `url(${book.imageLinks.smallThumbnail})`,
                      }}
                    ></div>
                    <div className="book-shelf-changer">
                      <select
                        onClick={(event) =>
                          handlechange(event.target.value, book)
                        }
                        defaultValue={shelfname}
                      >
                        <option value="none" disabled>
                          Move to...
                        </option>
                        <option value="currentlyReading">
                          Currently Reading
                        </option>
                        <option value="wantToRead">Want to Read</option>
                        <option value="read">Read</option>
                        <option value="none">None</option>
                      </select>
                    </div>
                  </div>
                  <div className="book-title">{book.title}</div>
                  <div className="book-authors">
                    {book.authors.map((author) => author)}
                  </div>
                </div>
              </li>
            ))}
        </ol>
      </div>
    </div>
  );
};

export default BookShelf;
BookShelf.prototype = {
  books: PropTypes.array.isRequired,
  changebooks: PropTypes.func.isRequired,
  shelfname: PropTypes.string.isRequired,
};
